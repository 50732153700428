"use client";

import React, { useMemo } from "react";
import {
  cn,
  convertJsonToUrlParams,
  formatCurrency,
  getRating,
  getTimeDataFromSearchResponse,
} from "@/lib/utils";
import { format } from "date-fns";
import { SingleCardSkeletonLoading } from "../listViewComponents/CardSkeletonLoading";
import { useInView } from "react-intersection-observer";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { FilterOption, HotelData } from "../../context/searchProvider";
import { SearchSessionV2 } from "@/generated/search.openapi";
import { X } from "lucide-react";

import "@/styles/hotelMapCard.css";
import { getApproximatePercentile, getPercentileNumber } from "../helper";

type Props = {
  hotel: HotelData;
  searchResponse?: SearchSessionV2;
  filters?: FilterOption;
  innerClassName?: string;
  mainClassName?: string;
  closeCard?: () => void;
};

const HotelMapCard: React.FC<Props> = ({
  hotel,
  filters,
  searchResponse,
  mainClassName,
  innerClassName,
  closeCard,
}) => {
  const { inView, ref } = useInView({
    initialInView: false,
    fallbackInView: false,
  });
  const { checkin, checkout } = getTimeDataFromSearchResponse(searchResponse);
  const onClick: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.stopPropagation();
    window.open(
      `/hotel/details/${hotel.vervotechPropertyId}${convertJsonToUrlParams({
        numberOfAdults:
          searchResponse?.request?.hotels?.searchOccupants?.numberOfAdults || 1,
        checkin: checkin ? format(checkin, "yyyy-MM-dd") : "",
        checkout: checkout ? format(checkout, "yyyy-MM-dd") : "",
      })}`,
      "_blank"
    );
  };

  const images = useMemo(() => {
    if (inView) {
      return hotel.images
        ?.filter((img) => {
          if (filters?.selectedImageCategories === undefined) return true;
          return img.category === filters.selectedImageCategories;
        })
        .map((img) => img.url);
    }
    return [];
  }, [filters?.selectedImageCategories, hotel.images, inView]);

  const imageGalleryItems: ReactImageGalleryItem[] = useMemo(() => {
    return (
      images?.map((image) => ({
        original: image,
        lazyLoad: true,
        infinite: true,
        renderItem: (item) => (
          <img
            src={item.original}
            alt={item.originalAlt}
            className="max-h-[250px] min-h-[250px] w-full rounded-t-lg object-cover"
          />
        ),
      })) || []
    );
  }, [images]);

  const displayNightlyRate =
    filters?.benefitsMetrics === "Points Only"
      ? hotel.lowestNightlyPoints
      : filters?.benefitsMetrics === "Cash Only"
        ? hotel.nightlyRate
        : hotel.nightlyRate;

  const displayTotalRate =
    filters?.benefitsMetrics === "Points Only"
      ? hotel.lowestPoints
      : filters?.benefitsMetrics === "Cash Only"
        ? hotel.lowestRate
        : hotel.lowestRate;

  const displayRateIcon =
    filters?.benefitsMetrics === "Points Only"
      ? "/assets/points-white.svg"
      : filters?.benefitsMetrics === "Cash Only"
        ? "/assets/dollar-white.svg"
        : "/assets/dollar-white.svg";

  const isPointsOnly = filters?.benefitsMetrics === "Points Only";

  const approximatePercentile = getApproximatePercentile(
    hotel,
    filters?.benefitsMetrics === "Points Only"
  );

  const percentileNumber = getPercentileNumber(
    hotel,
    filters?.benefitsMetrics === "Points Only"
  );

  return (
    <div className={cn("h-full w-full", mainClassName)} ref={ref}>
      {inView ? (
        <div
          className={cn(
            "relative flex w-full cursor-pointer flex-col gap-3 rounded-lg",
            innerClassName
          )}
          onClick={onClick}
        >
          <div className="absolute right-2 top-2 z-10">
            <div
              className="flex h-6 w-6 items-center justify-center rounded-full bg-white shadow-lg"
              onClick={(e) => {
                e.stopPropagation();
                closeCard?.();
              }}
            >
              <X className="h-5 w-5" />
            </div>
          </div>
          {images?.length ? (
            <ImageGallery
              autoPlay={false}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={false}
              showBullets={false}
              lazyLoad={true}
              items={imageGalleryItems}
              renderLeftNav={(onClickLeft) => (
                <img
                  src="/assets/arrow-left-circle.svg"
                  alt="left"
                  className="absolute left-1 top-1/2 z-[9] h-8 w-8 -translate-y-1/2 transform cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickLeft(e);
                  }}
                />
              )}
              renderRightNav={(onClickRight) => (
                <img
                  src="/assets/arrow-right-circle.svg"
                  alt="right"
                  className="absolute right-1 top-1/2 z-[9] h-8 w-8 -translate-y-1/2 transform cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickRight(e);
                  }}
                />
              )}
            />
          ) : (
            <div className="mx-4 flex min-h-[250px] items-center justify-center">
              <span className="text-md text-center font-normal text-primary text-opacity-40">
                Image not available
              </span>
            </div>
          )}
          <div className="flex w-full flex-col gap-1.5 px-2.5 pb-2.5">
            <div className="flex w-full items-start justify-between gap-2">
              <div className="flex flex-col text-base text-primary">
                <span className="line-clamp-2">{hotel.propertyName}</span>
              </div>
              {hotel.averageGuestRating && (
                <div className="mt-0.5 flex flex-col justify-center gap-x-1 text-right text-sm font-normal text-primary">
                  <span className="flex items-center justify-end gap-1">
                    <img
                      src="/assets/star.svg"
                      alt="Star"
                      className="inline h-3 w-3"
                    />
                    {getRating(hotel.averageGuestRating)}
                  </span>
                </div>
              )}
            </div>
            <div className="text-sm font-medium text-neutral-400">
              {hotel.chainName ? `${hotel.chainName} •` : ""} {hotel.stars}
              -star hotel
            </div>
            <div className="flex items-center justify-between">
              {displayNightlyRate && (
                <>
                  <div className="flex w-full items-center gap-1 text-neutral-400">
                    <img
                      src={displayRateIcon}
                      alt="rate"
                      className={cn(
                        isPointsOnly ? "h-4 w-4" : "-mr-0.5 h-4 w-2.5"
                      )}
                    />
                    <span className="text-primary">
                      {new Intl.NumberFormat("en-US").format(
                        displayNightlyRate
                      )}
                    </span>
                    <span className="text-xs">
                      /night •{" "}
                      {isPointsOnly
                        ? new Intl.NumberFormat("en-US").format(
                            displayTotalRate!
                          )
                        : formatCurrency(
                            displayTotalRate!,
                            hotel.lowestRateCurrency,
                            0
                          )}{" "}
                      total
                    </span>
                  </div>
                  <div
                    className={cn(
                      "flex flex-col items-center justify-start whitespace-nowrap rounded-[3px] px-1.5 py-0.5",
                      approximatePercentile === "low"
                        ? "bg-value-bad"
                        : approximatePercentile === "average"
                          ? "bg-value-average"
                          : approximatePercentile === "high"
                            ? "bg-value-good"
                            : ""
                    )}
                  >
                    <span className="flex flex-row items-center gap-1.5 text-xs font-normal text-white">
                      {approximatePercentile === "low"
                        ? "Weak value"
                        : approximatePercentile === "average"
                          ? "Okay value"
                          : approximatePercentile === "high"
                            ? "Great Value"
                            : ""}
                      <span className="text-sm">{percentileNumber}</span>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <SingleCardSkeletonLoading />
      )}
    </div>
  );
};

export default HotelMapCard;
