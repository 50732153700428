import React from "react";

const CardSkeletonLoading: React.FC = () => {
  return (
    <div className="relative mt-6 flex w-full flex-col gap-4 lg:w-[62%]">
      <div className="hidden gap-4 lg:flex">
        <div className="h-7 w-28 rounded-full bg-gray-700 px-3 pt-1.5" />
        <div className="h-7 w-28 rounded-full bg-gray-700 px-3 pt-1.5" />
        <div className="h-7 w-28 rounded-full bg-gray-700 px-3 pt-1.5" />
        <div className="h-7 w-28 rounded-full bg-gray-700 px-3 pt-1.5" />
        <div className="h-7 w-28 rounded-full bg-gray-700 px-3 pt-1.5" />
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        {[...Array(9)].map((_, i) => (
          <SingleCardSkeletonLoading key={i} />
        ))}
      </div>
    </div>
  );
};

export const SingleCardSkeletonLoading: React.FC = () => {
  return (
    <div className="flex h-[400px] w-full animate-pulse flex-col items-start justify-start gap-3 rounded-lg p-3 shadow">
      <div className="flex h-[320px] w-full rounded bg-gray-700" />
      <div className="flex w-full gap-3">
        <div className="h-6 w-full rounded bg-gray-700" />
        <div className="h-6 w-[50px] rounded bg-gray-700" />
      </div>
      <div className="mt-auto h-10 w-52 rounded-2xl bg-gray-700" />
    </div>
  );
};

export default CardSkeletonLoading;
