import React from "react";
import { FilterOption, HotelData } from "../../context/searchProvider";
import { getApproximatePercentile } from "../helper";
import { cn } from "@/lib/utils";
import { ApproximatePercentile } from "@/generated/search.openapi";

type Props = {
  hotel: HotelData;
  filters?: FilterOption;
  isHovered?: boolean;
  zIndex: number;
};

const HotelMarker: React.FC<Props> = ({
  hotel,
  filters,
  isHovered,
  zIndex,
}) => {
  const approximatePercentile = getApproximatePercentile(
    hotel,
    filters?.benefitsMetrics === "Points Only"
  );

  const showPoints = filters?.benefitsMetrics === "Points Only";

  if (showPoints && !hotel.lowestNightlyPoints) return null;

  const price = showPoints
    ? Intl.NumberFormat("en-US", {}).format(hotel.lowestNightlyPoints!)
    : Intl.NumberFormat("en-US", {}).format(hotel.nightlyRate!);

  return (
    <div
      className={cn(
        "flex gap-1 rounded-full px-4 py-2 text-primary",
        approximatePercentile === ApproximatePercentile.High
          ? "bg-value-good"
          : approximatePercentile === ApproximatePercentile.Average
            ? "bg-value-average"
            : approximatePercentile === ApproximatePercentile.Low
              ? "bg-value-bad"
              : "bg-background",
        isHovered ? "bg-primary-gold" : ""
      )}
      style={{ zIndex }}
    >
      {showPoints ? (
        <img
          src="/assets/points-white.svg"
          alt="Points"
          className="-ml-0.5 h-3 w-3"
        />
      ) : (
        <img
          src="/assets/dollar-white.svg"
          alt="Dollar"
          className="-ml-0.5 -mr-1 h-3 w-3"
        />
      )}
      {price}
    </div>
  );
};

export default HotelMarker;
