import React from "react";

type Props = {
  clusterNumber: number;
};

const ClusterMarker: React.FC<Props> = ({ clusterNumber }) => {
  return (
    <div className="rounded-full bg-white px-4 py-2 text-black">
      {clusterNumber} hotels
    </div>
  );
};

export default ClusterMarker;
